import React from "react"
import Layout from "../components/Layout"
import objectFitImages from "object-fit-images"
import { UserProvider } from "@auth0/nextjs-auth0"
import { useRouter } from 'next/router'
import * as gtag from '../lib/gtag'

import "swiper/css/bundle"
// swiper core styles
import "swiper/css"

// modules styles
import "swiper/css/pagination"
import "swiper/css/navigation"

//import "@fortawesome/fontawesome-free/css/all.min.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "../scss/style.default.scss"

const App = ({ Component, pageProps }) => {
  const router = useRouter()

  React.useEffect(() => {
    objectFitImages();
  }, []);

  React.useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // If you've used `withAuth`, pageProps.user can pre-populate the hook
  // If you haven't used `withAuth`, pageProps.user is undefined so the hook fetches the user from the API routes
  const { user } = pageProps;
  return (
    <UserProvider user={user}>
      <Layout {...pageProps}>
        <Component {...pageProps} />
      </Layout>
    </UserProvider>
  )
}

// This default export is required in a new `pages/_app.js` file.
export default App
